import { useApi } from '../modules/api'

export const workerSearch = async (value, limit = 5, filter = null) => {
	if (value === '' || value) {
		let getParams = `/worker/search?limit=${limit}&search=${value}`
		if (filter?.filterValue) getParams += `&role=${filter.filterValue}`
		const { get, data } = useApi(getParams)

		await get()

		const map = {}

		data.value.forEach(({ id, surname, name, middleName }) => {
			map[id] = `${surname} ${name} ${middleName || ''}`
		})

		return map
	}
}
