<template lang="pug">
.grid
	.flex.flex--jcsb(v-if="title")
		.title.mr-3 {{ title }}
		BaseInput(
			v-if="needSearch"
			v-model="search"
			placeholder="Поиск"
			icon="search"
			@update:modelValue="onSearch"
		)
	TheList(:map="map" :search="search" @onClick="onClick")
		BasePagination(
		v-show="pagination.show"
		v-model:value="pagination.page"
		:max="pagination.totalPages"
		@update:value="getData"
	)
.add(v-if="isAdmin && !plusHide")
	IconPlus(@click="createNew" color="white" :diameter="32" circle)
</template>

<script>
import { onMounted, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import { useApi } from '../modules/api'
import formattedDateTime from '../utils/formattedDateTime'
import { workerSearch } from '../utils/workerSearch'

import TheList from './TheList'

import BaseInput from './ui/BaseInput'
import BaseButton from './ui/BaseButton'
import BasePagination from './ui/BasePagination'

import IconPlus from './ui/icons/IconPlus'

export default {
	name: 'TheBook',
	components: {
		TheList,
		BasePagination,
		BaseInput,
		BaseButton,
		IconPlus
	},
	props: {
		endpoint: {
			type: String,
			required: true
		},
		path: {
			type: String,
			required: true
		},
		title: {
			type: [String, null],
			default: null
		},
		needSearch: {
			type: Boolean,
			default: false
		},
		left: {
			type: [Array, null],
			default: null
		},
		leftSub: {
			type: [Array, null],
			default: null
		},
		leftSubTitle: {
			type: [Array, null],
			default: null
		},
		right: {
			type: [Array, null],
			default: null
		},
		rightSub: {
			type: [Array, null],
			default: null
		},
		customClick: {
			type: Boolean,
			default: false
		},
		plusHide: {
			type: Boolean,
			default: false
		},
		filter: {
			type: Object,
			default: null
		}
	},
	emits: ['onClick'],
	setup (props, { emit }) {
		const isAdmin = JSON.parse(localStorage.account).isAdmin

		const map = ref({})

		const pagination = reactive({
			show: true,
			page: 0,
			size: 20,
			totalPages: 0
		})

		const search = ref('')

		onMounted(async () => {
			await getData()
		})

		watch(
			() => props.endpoint,
			async () => {
				await getData()
			}
		)

		const getData = async () => {
			const { get, data } = useApi(props.endpoint)
			let getParams = `?offset=0&page=${pagination.page}&size=${pagination.size}`
			if (props?.filter) getParams += `&role=${props.filter.filterValue}`
			await get(getParams)

			const { content, totalPages } = data.value
			pagination.totalPages = totalPages
			map.value = {}
			content.forEach(line => {
				const joiner = name => props[name]?.map(field => {
					if (typeof field === 'object' && field?.type) {
						if (field.type === 'dateTimestapm') {
							return getDateField(field, line)
						}
						if (field.type === 'custom') {
							return getCustom(field, line)
						}
						return ''
					}
					return line[field]
				}).join(' ')
				const leftSub = joiner('leftSub')
				map.value[line.id] = {
					left: joiner('left'),
					leftSub: leftSub && props.leftSubTitle ? `${props.leftSubTitle} ${leftSub}` : leftSub,
					right: joiner('right'),
					rightSub: joiner('rightSub')
				}
			})
		}
		const getDateField = (field, line) => {
			const value = line
			let dateString = ''
			for (const key of field.keys) {
				dateString += formattedDateTime(value[key] * 1000).split(' ')[0].toString().replace(/-/g, '.') + field.separator
			}
			return dateString.slice(0, -field.separator.length) + `${field?.endStr ? field?.endStr : ''}`
		}

		const getCustom = (field, line) => {
			const keys = field.key.split('.')
			let value = line
			for (const key of keys) {
				value = value[key]
			}
			return value + `${field?.endStr ? field?.endStr : ''}`
		}

		const onSearch = async (value) => {
			if (value === '') {
				pagination.show = true
				await getData()
			} else {
				pagination.show = false
				map.value = {}
				const searchMap = await workerSearch(value, 10, props?.filter)
				Object.entries(searchMap).forEach(([key, value]) => {
					map.value[key] = {
						left: value
					}
				})
			}
		}

		const router = useRouter()

		const onClick = (id) => {
			props.customClick
				? emit('onClick', { id, value: map.value[id] })
				: router.push(`/${props.path}/${id}`)
		}

		const createNew = () => {
			router.push(`/${props.path}/new`)
		}

		return {
			isAdmin,
			map,
			pagination,
			search,
			onSearch,
			getData,
			onClick,
			createNew
		}
	}
}
</script>
